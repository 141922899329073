const Translates = {
    "en": {
        "Do you want to download a file": "Do you want to download a file",
        "Downloading this file costs:": "Downloading this file costs:",
        "tokens": "tokens",
        "free": "free",
        "Download not available:": "Download not available:",
        "You have reached your download limit.": "You have reached your download limit.",
        "You don't have enough tokens on your account.": "You don't have enough tokens on your account.",
        "Download": "Download",
        "Licenses not found": "Licenses not found"
    },
    "ru": {
        "Do you want to download a file": "Вы уверены что хотите скачать данный файл",
        "Downloading this file costs:": "Скачивание этого файла стоит:",
        "tokens": "токенов",
        "free": "бесплатно",
        "Download not available:": "Скачивание недоступно:",
        "You have reached your download limit.": "Вы достигли лимита скачивания файлов.",
        "You don't have enough tokens on your account.": "У вас недостаточно токенов на вашем аккаунте.",
        "Download": "Скачать",
        "Licenses not found": "Лицензии не найдены"
    }
}

Translates.getMessage = (locale, message) => {
    console.log(Translates[locale]);
    if(typeof Translates[locale] == "undefined") {
        return message;
    }
    console.log('123');
    if(typeof Translates[locale][message] == "undefined") {
        return message;
    }
    console.log('321');
    return Translates[locale][message];
}

export default Translates;
