$(document).ready(function (){

    const FORM = $('form[name=buy_tokens]');
    if(FORM.length <= 0) return;

    $('#dataTablePayments').DataTable({
        "order": [[ 5, 'desc' ]],
    });

    const CURRENCY_CODE = {
        'RUB': '₽',
        'USD': '$',
        'EUR': '€'
    };
    const TOKEN_COST = {
        'RUB': FORM.find('input[name=RUB_Cost]').val(),
        'USD': FORM.find('input[name=USD_Cost]').val(),
        'EUR': FORM.find('input[name=EUR_Cost]').val()
    }
    let SELECT_CURRENCY = FORM.find('input[name=currency]').val();

    $(document).on('keyup', 'input[name=tokens]', function () {
        setAmount();
    });

    $(document).on('change', 'input[name=currency]', function (){
        SELECT_CURRENCY = $(this).val();
        FORM.find('#amount_currency').text(CURRENCY_CODE[SELECT_CURRENCY]);
        setAmount();
    });

    const setAmount = () => {
        let total_cost = TOKEN_COST[SELECT_CURRENCY] * FORM.find('input[name=tokens]').val();
        FORM.find('input[name=amount_currency]').val(total_cost);
    }

});
