window.$ = window.jQuery = require('jquery');

require('./bootstrap');
require('./payment.js');
require('./custom');
require('datatables.net');
require('datatables.net-bs');
require('datatables.net-fixedcolumns-bs');

import Translates from './translates.js';

var ajaxQueries = new Array();
var dataTable = false;

const LOCALE = $(document).find('html').attr('lang');
const dataTableLangUrl = "/js/lang/" + LOCALE + ".json";

$(document).ready(function (){

    if(!dataTable) {

        if($('#files-list').length > 0) {
            dataTable = $('#files-list').DataTable({
                fixedColumns: true,
                pageLength: 25,
                searching: false,
                lengthChange: false,
                language: {
                    url: dataTableLangUrl
                }
            });

            dataTable.on('init.dt', function (e){
                $('#spinner-files-list').hide();
                $('#files-list').show();
            })
        }
    }

    $('#dataTableActions').DataTable({
        "order": [[ 3, 'desc' ]],
    });

    $(document).on('click', '#show-licenses', function (e) {
        e.preventDefault();
        const id = $(this).data('id');
        const tableBody = $('#licensesContent table tbody');

        $('#licensesContent #keyNumber').text(id);
        tableBody.html('<tr><td colspan="2"><div class="d-flex justify-content-center"><div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div></div></td></tr>');
        $('#licensesContent').show();

        stopAjaxQuery();
        let ajaxQuery = $.ajax(
            {
                type: 'POST',
                url: '/licenses',
                data: {
                    id: id
                },
                dataType: 'json',
                cache: false,
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                success: function (result) {
                    tableBody.html('');
                    if(result.length > 0) {
                        result.forEach(function(item) {
                            tableBody.append('<tr><td>' + item.code + '</td><td>' + item.name + '</td></tr>')
                        });
                    } else {
                        tableBody.append('<tr><td colspan="2">' + Translates.getMessage(LOCALE, 'Licenses not found') + '</td></tr>');
                    }
                }
            });
        ajaxQueries.push(ajaxQuery);
    });

    $(document).on('click', '#attention_file .modal-footer a', function () {
        $(this).parent().find('button').click();
    });

    $(document).on("click", "button[id^=download-file]", function (e){
        e.preventDefault();

        let id_file = $(this).data('id');
        let modal = $('#attention_file');
        let modal_button = $(document).find('button[id^=download-modal]');
        let modal_link = modal.find('.modal-footer a');

        stopAjaxQuery();
        let ajaxQuery = $.ajax(
            {
                type: 'GET',
                url: '/info',
                data: {
                    file: id_file
                },
                dataType: 'json',
                cache: false,
                success: function (result) {
                    let body = Translates.getMessage(LOCALE, 'Do you want to download a file') + ' "' + result.name + '"<br/>' +
                        Translates.getMessage(LOCALE, 'Downloading this file costs:') + '  ' + (result.price > 0 ? result.price + ' ' + Translates.getMessage(LOCALE, 'tokens') : ' ' + Translates.getMessage(LOCALE, 'free')) + '<br/>';
                    if(result.success) {
                        body = body + '';
                        modal_link.attr('href', result.link).show();
                    } else {
                        body = body + '<p class="text-danger">' + Translates.getMessage(LOCALE, 'Download not available:') + '<br/>';
                        if(result.downloads == false) {
                            body = body + '- ' + Translates.getMessage(LOCALE, 'You have reached your download limit.') + '<br/>';
                        }
                        if(result.prices == false) {
                            body = body + Translates.getMessage(LOCALE, "You don't have enough tokens on your account.") + '<br/>';
                        }
                        body = body + '</p>';
                        modal_link.attr('href', '#').hide();
                    }
                    modal.find('.modal-body').html(body);

                    modal_button.click();
                }
            });
        ajaxQueries.push(ajaxQuery);
    });

    $( "#search-files" ).on('keyup', function (){
        let text = $(this).val();
        if(text.length >= 3) {
            findFiles(text);
        }
    });

    function findFiles(text) {
        $('#files-list').show();
        stopAjaxQuery();
        //table.html('');

        if(dataTable) {
            //$(document).find('#files-list_wrapper').hide();
            dataTable.clear();
        }

        let ajaxQuery = $.ajax(
            {
                type: 'GET',
                url: '/search',
                data: {
                    search: text
                },
                dataType: 'json',
                cache: false,
                success: function (result) {
                    if(result.length > 0) {
                        result.forEach((element) => {
                            dataTable.row.add([
                                element.marka,
                                element.model,
                                element.engine,
                                /*element.file,*/
                                element.info,
                                element.infocar,
                                element.price,
                                '<button id="download-file-' + element.id + '" type="button" class="btn btn-primary" data-id="' + element.id + '">' + Translates.getMessage(LOCALE, "Download") + '</button>' +
                                '                <button style="display: none" id="download-modal-' + element.id + '" type="button" data-bs-toggle="modal" data-bs-target="#attention_file">' + Translates.getMessage(LOCALE, "Download") + '</button>'
                            ]).draw();
                        });
                        $(document).find('#files-list_wrapper').show();
                    } else {
                        if(dataTable)
                            dataTable.clear().draw();
                    }

                }
            });
        ajaxQueries.push(ajaxQuery);
    }

    function stopAjaxQuery() {
        if (typeof(ajaxQueries) == 'undefined')
            ajaxQueries = new Array();
        for(let i = 0; i < ajaxQueries.length; i++) {
            if (typeof ajaxQueries[i] != 'undefined')
                ajaxQueries[i].abort();
        }
        ajaxQueries = new Array();
    }
});


